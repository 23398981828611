import { createAction, props } from '@ngrx/store';
import { GthEventItemModel, GthTeamModel } from '@sentinels/models';

export enum TeamsActionTypes {
  SetActiveTeam = '[Active Team] Set Active Team',

  SetActiveTeamById = '[Active Team] Set Active Team by Id',
  SetActiveTeamByIdSuccess = '[Active Team] Set Active Team by Id Success',
  SetActiveTeamByIdError = '[Active Team] Set Active Team by Id Error',

  LoadEvents = '[Active Team] Load Events',
  LoadEventsSuccess = '[Active Team] Load Events Success',
  LoadEventsError = '[Active Team] Load Events Error',
}

export const SetActiveTeam = createAction(
  TeamsActionTypes.SetActiveTeam,
  props<{ activeTeam: GthTeamModel }>(),
);

export const SetActiveTeamById = createAction(
  TeamsActionTypes.SetActiveTeamById,
  props<{ activeTeamId: string }>(),
);

export const SetActiveTeamByIdSuccess = createAction(
  TeamsActionTypes.SetActiveTeamByIdSuccess,
  props<{ activeTeam: GthTeamModel }>(),
);

export const SetActiveTeamByIdError = createAction(
  TeamsActionTypes.SetActiveTeamByIdError,
  props<{ error: unknown }>(),
);

export const LoadEvents = createAction(
  TeamsActionTypes.LoadEvents,
  props<{ activeTeamId?: string }>(),
);

export const LoadEventsSuccess = createAction(
  TeamsActionTypes.LoadEventsSuccess,
  props<{ events: GthEventItemModel[] }>(),
);

export const LoadEventsError = createAction(
  TeamsActionTypes.LoadEventsError,
  props<{ error: unknown }>(),
);
